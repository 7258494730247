import { createContext, useContext, useReducer } from "react"

const DataGridContext = createContext(null)
const DataGridDispatchContext = createContext(null)

export function DataGridProvider({ children }) {
  const [ dataGrid, dispatch ] = useReducer(dataGridReducer, initialState)
  
  return (
    <DataGridContext.Provider value={dataGrid}>
      <DataGridDispatchContext.Provider value={dispatch}>
        { children}
      </DataGridDispatchContext.Provider>
    </DataGridContext.Provider>   
  )
}

export function useDataGrid() {
  return useContext(DataGridContext)
}

export function useDataGridDispatch() {
  return useContext(DataGridDispatchContext)
}

function dataGridReducer(state, action) {
  switch(action.type) {
    case "INITIALIZE_GRID": return initializeGrid(state, action) 
    case "SET_COLUMN_WIDTH": return setColumnWidth(state, action)
    case "SORT_ROWS": return sortRows(state, action)
    case "ADD_FILTER": return addFilter(state, action)
    case "CLEAR_FILTER": return clearFilter(state, action)
    case "REMOVE_FILTER": return removeFilter(state, action)
    case "ADD_BULK_FILTERS": return addBulkFilters(state, action)
    case "REMOVE_BULK_FILTERS": return removeBulkFilters(state, action)
    case "RESET_ALL_FILTERS": return resetAllFilters(state, action)
    case "UPDATE_RECORD": return updateRecord(state, action)
    case "END_DRAG": return endDrag(state, action)
    case "SET_ACTIVE_SCENARIO": return setActiveScenario(state, action)
    case "FREEZE_COLUMN": return freezeColumn(state, action)
    case "UPDATE_RECORD_COMMENTS": return updateRecordComments(state, action)
    case "UPDATE_SEARCH_FILTER": return updateSearchFilter(state, action)
    case "APPLY_CUSTOM_LOOKUPS": return applyCustomLookups(state, action)
    default: return state
  }
}

const initialState = {
  columns: [],
  columnWidth: "100px",
  rowHeight: 50,
  activeSort: 'BRANCH_ID',
  activeSortDirection: 'des',
  filters: [],
  activeScenario: null,
  rows: [],
  lastRead: 0
}

function addFilter(state, action) {
  const { key, value } = action.payload, existingFilter = state.filters.find(f => f.key === key)
  const updatedFilters = state.filters.filter(f => f.key !== key)

  if (["column", "area"].includes(existingFilter?.category)) {
    updatedFilters.push({ ...existingFilter, values: [...existingFilter.values, value] })
    return { ...state, filters: updatedFilters }
  } else if (existingFilter) {
    return { ...state, filters: [...updatedFilters, { ...action.payload, values: [value] }] }
  } else {
    const { value, ...props } = action.payload
    return { ...state, filters: [...state.filters, {
      ...props, values: [value]
    }]}
  }
}

function applyCustomLookups(state, action) {
  const customLookups = action.payload
  return {
    ...state,
    rows: state.rows.map(row => {
      const customLookup = customLookups.find(lookup => row[lookup.column] === lookup.value)
      if (customLookup) {
        return {
          ...row,
          "SEND_COMMENT_TO": customLookup.assignTo
        }
      } else {
        return {
          ...row,
          "SEND_COMMENT_TO": ""
        }
      }
    })
  }
}

function clearFilter(state, action) {
  return {
    ...state,
    filters: [...state.filters.filter(f => f.key !== action.payload.key)]
  }
}

function endDrag(state, action) {
  const columns = state.columns.map(column => {
    return {
      ...column,
      frozen: false
    }
  })
  return {
    ...state,
    columns: _moveArrayItem(columns, action.payload.source, action.payload.target),
    columnWidth: _moveArrayItem(state.columnWidth.split(" "), action.payload.source, action.payload.target).join(" ")
  }
}

function freezeColumn(state, action) {
  const { key, setting } = action.payload
  const idx = state.columns.findIndex(column => column.key === key)
  return {
    ...state,
    columns: state.columns.map((column, colIdx) => {
      if ((idx === colIdx) || (!setting && idx <= colIdx)) {
        return {
          ...column,
          frozen: setting
        }
      } else {
        return column
      }
    })
  }
}

function initializeGrid(state, action) {
  const columns = action.data.columns.map(column => ({
    ...column,
    width: column.width || "175px",
    frozen: column.frozen || false
  }))
  return {
    ...state,
    columns: columns,
    rows:      action.data.rows.sort((a, b) => {
      if (a[state.activeSort] > b[state.activeSort]) return -1
      if (a[state.activeSort] < b[state.activeSort]) return 1
      return 0
    }),
    columnWidth: columns.map(column => column.width).join(" ")
  }
}

function removeFilter(state, action) {
  return {
    ...state, 
    filters: state.filters.map(activeFilter => {
      if (activeFilter.key === action.payload.key && activeFilter.values.includes(action.payload.value)) {
        if (activeFilter.values.length > 1) {
          return { ...activeFilter, values: [...activeFilter.values.filter(value => value !== action.payload.value)] }
        } else {
          return undefined
        }
      } else {
        return activeFilter
      }
    }).filter(e => e)
  }
}

function addBulkFilters(state, action) {
  const { key, values, greaterThanComparison, lessThanComparison } = action.payload, existingFilter = state.filters.find(f => f.key === key)
  const updatedFilters = state.filters.filter(f => f.key !== key)
  if (existingFilter) {
    return { ...state, filters: [...updatedFilters, { ...existingFilter, values, greaterThanComparison, lessThanComparison }] }
  } else {
    return { ...state, filters: [...state.filters, action.payload] }
  }
}

function removeBulkFilters(state, action) {
  const { key } = action.payload
  return { ...state, filters: state.filters.filter(f => f.key !== key) }
}

function resetAllFilters(state, action) {
  return {
    ...state,
    filters: []
  }
}

function setActiveScenario(state, action) {
  return {
    ...state,
    activeScenario: action.payload.scenario
  }
}

function setColumnWidth(state, action) {
  return {
    ...state,
    columnWidth: action.columnWidth
  }
}

function sortRows(state, action) {
  const { prop, direction } = action.payload
  return {
    ...state,
    activeSort: prop,
    activeSortDirection: direction,
    rows:
      [...state.rows.sort((a, b) => {
        let aVal = a[prop], bVal = b[prop]
        const numberFields = [
          "BRANCH_ID", "PAY_VENDOR_ID", "DAYS_TO_PAY", "DISC_AMT",
          "DATE_DIFFNUM", "TTL_RCVG_AMT", "INV_TOTAL_AMT", "INV_FRT_AMT",
          "PO_VENDOR_ID", "ORDER_ID", "DIR_INVOICES_ID", "NUM_DAYS_ONHOLD"
        ]
        const dateFields = [
          "PAY_DUE_DATE", "AP_INV_DATE", "INV_ENTRY_DATE"
        ]
        if (numberFields.includes(prop)) { 
          aVal = !isNaN(parseFloat(a[prop])) ? parseFloat(a[prop]) : null 
          bVal = !isNaN(parseFloat(b[prop])) ? parseFloat(b[prop]) : null
        }
        if (dateFields.includes(prop)) { 
          aVal = !isNaN(Date.parse(a[prop])) ? Date.parse(a[prop]) : null 
          bVal = !isNaN(Date.parse(b[prop])) ? Date.parse(b[prop]) : null 
         }
        if (aVal != null && bVal != null && aVal > bVal) return direction === "asc" ? 1 : -1
        if (aVal != null && bVal != null && bVal > aVal) return direction === "asc" ? -1 : 1
        if (aVal != null && bVal == null) return direction === "asc" ? 1 : -1
        if (bVal != null && aVal == null) return direction === "asc" ? -1 : 1
        return 0
      })]
  }
}

function updateRecord(state, action) {
  return {
    ...state,
    rows: [...state.rows.map(row => {
      if (row.id === action.payload.id) {
        const { id, ...updatedFields } = action.payload
        return { ...row, ...updatedFields }
      } else {
        return row
      }
    })]
  }
}

function updateRecordComments(state, action) {
  const lastRead = Math.floor(Date.now() / 1000)
  return {
    ...state,
    rows: [...state.rows.map(row => {
      const comment = action.payload.find(comment => comment.RECORD_ID === row.id)
      if (comment) {
        const { 
          _ts: COMMENT_LAST_UPDATED,
          id: COMMENT_ID,
          ...commentFields
        } = comment
        return {
          ...row,
          ...commentFields,
          COMMENT_LAST_UPDATED,
          COMMENT_ID,
          COMMENT_LAST_READ: lastRead
        }
      } else {
        return row
      }
    })],
    lastRead: lastRead
  }
}

function updateSearchFilter(state, action) {
  const filter = state.filters.find(filter => filter.category === "search")
  const updatedFilters = state.filters.filter(filter => filter.category !== "search")
  if (filter) {
    updatedFilters.push({ ...filter, ...action.payload })
    return {
      ...state, 
      filters: updatedFilters
    }
  } else {
    updatedFilters.push(action.payload)
    return {
      ...state,
      filters: updatedFilters
    }
  }
}

function _moveArrayItem(array, from, to) {
  return (array.slice(), array.splice(to, 0, ...array.splice(from, 1)), array)
}