import HeaderContainer from "../containers/HeaderContainer";
import RecordContainer from "../containers/RecordContainer";
import UserSettingsModal from "../containers/UserSettingsModal";
import UserSchemaModal from "../containers/UserSchemaModal";
import { useSettings, useSettingsDispatch } from "../contexts/SettingsContext";
import { useFetch } from "../hooks/useFetch";
import { get } from "../lib/api";
import { useMsal } from "@azure/msal-react";
import { useEffect, useMemo } from "react";
import { useGroupsDispatch } from "../contexts/GroupsContext";
import Spinner from "react-bootstrap/esm/Spinner";
import GroupSettingsModal from "../containers/GroupSettingsModal";

/**
 * Default application home page.
 * Renders HeaderContainer, RecordContainer.
 * Conditionally renders UserSettingsModal, DistrictSettingsModal.
 * @returns {React.JSX.Element}
 */
export default function Home() {
  const { accounts } = useMsal();
  const userId = accounts[0].idTokenClaims.sub;
  const { userSettings, groupSettings, userSchema } = useSettings();
  const groupsDispatch = useGroupsDispatch();
  const settingsDispatch = useSettingsDispatch();
  const { value, isLoading } = useFetch(get, "groups");
  const { value: userProfile } = useFetch(get, `user-profiles/${userId}`);
  let defaultDistrict = useMemo(
    () => userProfile?.DEFAULT_DISTRICT,
    [userProfile]
  );

  useEffect(() => {
    if (value && userProfile) {
      let setDefaultDistrict = defaultDistrict || value[0].districts[0];
      value[0].districts.splice(0, 0, setDefaultDistrict);
      groupsDispatch({
        type: "SET_GROUPS",
        payload: value,
      });
      settingsDispatch({
        type: "LOAD_GROUP_SETTINGS",
        payload: value[0].config,
      });
    }
  }, [groupsDispatch, settingsDispatch, value, userProfile, defaultDistrict]);
  return isLoading ? (
    <div
      className="loading"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 200px)",
      }}
    >
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  ) : (
    <>
      <HeaderContainer />
      <main>
        {<UserSchemaModal show={userSchema.modalIsActive} />}
        {<UserSettingsModal show={userSettings.modalIsActive} />}
        {<GroupSettingsModal show={groupSettings.modalIsActive} />}
        <RecordContainer />
      </main>
    </>
  );
}
