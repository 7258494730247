import { useCallback, useMemo } from "react"
import { useSettings } from "../contexts/SettingsContext"
import { useGroups } from "../contexts/GroupsContext"
import { useMsal } from "@azure/msal-react"



export default function SetDefaultDistrictButton() {
  const { instance, accounts } = useMsal()
  const userId = accounts[0].idTokenClaims.sub
  const { activeDistrict } = useGroups()
  const { userSettings } = useSettings()
  const defaultDistrict = useMemo(() => userSettings.DEFAULT_DISTRICT, [userSettings])
  const notDefault = useMemo(() => activeDistrict !== defaultDistrict, [activeDistrict, defaultDistrict])

  const setDefault = useCallback(async () => {
    const url = process.env.REACT_APP_API.concat(`/user-profiles/${userId}/district`)
    const request = { account: accounts[0] }
    const { idToken } = await instance.acquireTokenSilent(request)
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${idToken}`
      }, body: JSON.stringify({
        DEFAULT_DISTRICT: {
          ...activeDistrict
        }
      })
    })
    
    if (response.ok) {
      console.log("All set!")
    }
  }, [accounts, instance, userId, activeDistrict])

  return (
    <div className="buttonBox">
      {
        <button className={`${ notDefault ? "active" : ""}`} style={{width: "90px"}} onClick={setDefault}>
          Set Default
        </button>
      }
    </div>
  )
}
