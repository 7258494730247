import { useDataGrid, useDataGridDispatch } from "./context/DataGridContext";
import { useCallback, useState, useRef } from "react";

export default function DataGridHeaderCellFilterComparisons({
  column,
  filteredOptions,
}) {
  const dispatch = useDataGridDispatch();
  const { rows, filters } = useDataGrid();

  const columnGtValue = filters.find(
    (f) => f.key === column.key && f.greaterThanComparison
  )?.greaterThanComparison;
  const columnLtValue = filters.find(
    (f) => f.key === column.key && f.lessThanComparison
  )?.lessThanComparison;
  const greaterThanRef = useRef();
  const lessThanRef = useRef();
  const [columnGtValues] = useState(columnGtValue);
  const [columnLtValues] = useState(columnLtValue);
  const [greaterThanValues, setGreaterThanValues] = useState({});
  const [lessThanValues, setLessThanValues] = useState({});

  const onGreaterThanInputChange = useCallback(
    (event) => {
      if (greaterThanRef.current.checked) return;
      setGreaterThanValues({
        ...greaterThanValues,
        [column.key]: event.target.value,
      });
    },
    [greaterThanValues, column]
  );

  const onLessThanInputChange = useCallback(
    (event) => {
      if (lessThanRef.current.checked) return;
      setLessThanValues({
        ...lessThanValues,
        [column.key]: event.target.value,
      });
    },
    [lessThanValues, column]
  );

  const onComparisonChange = useCallback(
    (event) => {
      let interval;
      let actionType = "ADD_BULK_FILTERS";
      let nonComparisonValues;
      let comparedValues;
      let removeGreaterThanValues = false;
      let removeLessThanValues = false;
      let greaterThanValue =
        parseFloat(greaterThanValues[column.key]) || parseFloat(columnGtValue);
      let lessThanValue =
        parseFloat(lessThanValues[column.key]) || parseFloat(columnLtValue);
      const comparison = event.target.dataset.value;

      if (event.target.value === "") return;

      rows.forEach((row) => filteredOptions.push(row[column.key]));
      comparison === "greaterthan"
        ? (greaterThanValue = parseFloat(event.target.value))
        : (lessThanValue = parseFloat(event.target.value));

      if (!event.target.checked) {
        comparison === "greaterthan"
          ? (removeGreaterThanValues = true)
          : (removeLessThanValues = true);
        dispatch({
          type: "REMOVE_BULK_FILTERS",
          payload: {
            category: "column",
            key: column.key,
            displayName: column.name,
          },
        });
      }

      let intervalCheck =
        !(removeGreaterThanValues || removeLessThanValues) &&
        ((columnLtValue && comparison === "greaterthan") ||
          (columnGtValue && comparison === "lessthan"));
      let updateFromInterval = !!(
        (removeGreaterThanValues && columnLtValue) ||
        (removeLessThanValues && columnGtValue)
      );
      interval =
        greaterThanValue <= lessThanValue &&
        (intervalCheck || updateFromInterval)
          ? "intersection"
          : false;
      interval =
        greaterThanValue >= lessThanValue &&
        (intervalCheck || updateFromInterval)
          ? "union"
          : interval;

      if (interval === "intersection") {
        if (lessThanValue === greaterThanValue) {
          comparedValues = filteredOptions;
        } else if (updateFromInterval) {
          nonComparisonValues =
            comparison === "greaterthan"
              ? getNonIntervalLessThanValues(lessThanValue, filters, column)
              : getNonIntervalGreaterThanValues(
                  greaterThanValue,
                  filters,
                  column
                );

          comparedValues =
            comparison === "greaterthan"
              ? getLessThanValues(lessThanValue, filteredOptions)
              : getGreaterThanValues(greaterThanValue, filteredOptions);
        } else {
          nonComparisonValues =
            comparison === "greaterthan"
              ? getNonIntervalLessThanValues(lessThanValue, filters, column)
              : getNonIntervalGreaterThanValues(
                  greaterThanValue,
                  filters,
                  column
                );
          comparedValues = getIntersectionValues(
            greaterThanValue,
            lessThanValue,
            filteredOptions
          );
        }
      } else if (interval === "union") {
        if (updateFromInterval) {
          nonComparisonValues = getNonUnionValues(
            greaterThanValue,
            lessThanValue,
            filters,
            column
          );
          comparedValues =
            comparison === "greaterthan"
              ? getLessThanValues(lessThanValue, filteredOptions)
              : getGreaterThanValues(greaterThanValue, filteredOptions);
        } else {
          comparedValues = getUnionValues(
            greaterThanValue,
            lessThanValue,
            filteredOptions
          );
          nonComparisonValues = getNonUnionValues(
            greaterThanValue,
            lessThanValue,
            filters,
            column
          );
        }
      } else if (comparison === "greaterthan") {
        nonComparisonValues = getNonIntervalGreaterThanValues(
          greaterThanValue,
          filters,
          column
        );
        if (!removeGreaterThanValues) {
          lessThanValue = undefined;
          comparedValues = getGreaterThanValues(
            greaterThanValue,
            filteredOptions
          );
        } else {
          if (nonComparisonValues.length === 0) {
            actionType = "REMOVE_BULK_FILTERS";
          } else {
            lessThanValue = undefined;
            comparedValues = nonComparisonValues;
          }
        }
      } else if (comparison === "lessthan") {
        nonComparisonValues = getNonIntervalLessThanValues(
          lessThanValue,
          filters,
          column
        );
        if (!removeLessThanValues) {
          greaterThanValue = undefined;
          comparedValues = getLessThanValues(lessThanValue, filteredOptions);
        } else {
          if (nonComparisonValues.length === 0) {
            actionType = "REMOVE_BULK_FILTERS";
          } else {
            greaterThanValue = undefined;
            comparedValues = nonComparisonValues;
          }
        }
      } else {
        throw new Error(
          "Invalid comparison value; must be 'greaterthan', 'lessthan', 'intersection', or 'union'"
        );
      }

      nonComparisonValues = [...new Set(nonComparisonValues)];
      comparedValues = comparedValues.concat(nonComparisonValues);
      comparedValues = [...new Set(comparedValues)];

      dispatch({
        type: actionType,
        payload: {
          category: "column",
          key: column.key,
          displayName: column.name,
          values: comparedValues,
          greaterThanComparison: removeGreaterThanValues
            ? undefined
            : greaterThanValue,
          lessThanComparison: removeLessThanValues ? undefined : lessThanValue,
          apply: function (record, values) {
            if (isRecordBlank(record[column.key])) {
              return values.includes("(blank)");
            }
            return values.includes(record[column.key]);
          },
        },
      });
    },
    [
      dispatch,
      rows,
      filters,
      greaterThanValues,
      lessThanValues,
      columnGtValue,
      columnLtValue,
      filteredOptions,
      column,
    ]
  );

  return (
    <>
      <label key="greaterthan">
        <input
          type="checkbox"
          data-value="greaterthan"
          ref={greaterThanRef}
          onChange={onComparisonChange}
          checked={
            !!filters.find(
              (f) => f.key === column.key && f.greaterThanComparison
            )
          }
          value={
            greaterThanValues[column.key]
              ? greaterThanValues[column.key]
              : columnGtValue
          }
        />
        &gt;
        <input
          className="comp-input"
          type="number"
          onChange={onGreaterThanInputChange}
          value={columnGtValues}
        />
      </label>
      <label key="lessthan">
        <input
          type="checkbox"
          data-value="lessthan"
          ref={lessThanRef}
          onChange={onComparisonChange}
          checked={
            !!filters.find((f) => f.key === column.key && f.lessThanComparison)
          }
          value={
            lessThanValues[column.key]
              ? lessThanValues[column.key]
              : columnLtValue
          }
        />
        &lt;
        <input
          className="comp-input"
          type="number"
          onChange={onLessThanInputChange}
          value={columnLtValues}
        />
      </label>
      <br />
    </>
  );
}

function getGreaterThanValues(greaterThanValue, filteredOptions) {
  return filteredOptions.filter((val) =>
    val > greaterThanValue ? Number.isFinite(val) : false
  );
}

function getLessThanValues(lessThanValue, filteredOptions) {
  return filteredOptions.filter((val) =>
    val < lessThanValue ? Number.isFinite(val) : false
  );
}

function getUnionValues(greaterThanValue, lessThanValue, filteredOptions) {
  return filteredOptions.filter((val) =>
    val > greaterThanValue || val < lessThanValue ? Number.isFinite(val) : false
  );
}

function getIntersectionValues(
  greaterThanValue,
  lessThanValue,
  filteredOptions
) {
  return filteredOptions.filter((val) =>
    val > greaterThanValue && val < lessThanValue ? Number.isFinite(val) : false
  );
}

function getNonIntervalLessThanValues(lessThanValue, filters, column) {
  return filters
    .find((f) => f.key === column.key)
    ?.values.filter((val) =>
      isValueBlank(val)
        ? val
        : val > lessThanValue
        ? Number.isFinite(val)
        : false
    );
}

function getNonIntervalGreaterThanValues(greaterThanValue, filters, column) {
  return filters
    .find((f) => f.key === column.key)
    ?.values.filter((val) =>
      isValueBlank(val)
        ? val
        : val < greaterThanValue
        ? Number.isFinite(val)
        : false
    );
}

function getNonUnionValues(greaterThanValue, lessThanValue, filters, column) {
  return filters
    .find((f) => f.key === column.key)
    ?.values.filter((val) =>
      isValueBlank(val)
        ? val
        : val < greaterThanValue && val > lessThanValue
        ? Number.isFinite(val)
        : false
    );
}

function isValueBlank(value) {
  return ["(blank)"].includes(value);
}
function isRecordBlank(value) {
  return ["", " ", null, undefined].includes(value);
}
